.subscriber {
     .c-main {
          padding-top: 0;

          .main-breadcrumb {
               padding-left: 18px;
               
               &.c-subheader {
                    margin-bottom: 2rem;
                    background-color: #f8f9fa;
                    border-bottom: 0;
                    min-height: 30px;
                    width: 100%;
               }

               .c-subheader-nav {
                    min-height: 29px;
                    font-size: 11px;
                    color: #73818f;
                    font-family: Roboto;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 13px;
                    transform: translateX(-10px);

                    li {
                         &.active {
                              color: #73818f !important;
                              font-weight: 400;
                         }
                    }
               }
          }
     }

     .c-subheader {
          .breadcrumb-item {
               margin-left: -1px;
          }
     }

     .c-wrapper.v2 {
          .main-breadcrumb.c-subheader {
               margin-bottom: 26px;
          }
     }
}

body {
     &.ll-disable-scroll {
          .sidebar.new-sidebar {
               & ~ .c-wrapper.v2 {
                    .header.new-header {
                         z-index: 4030;
          
                         .get-started-bar {
                              height: 0;
                              opacity: 0;
                              overflow: hidden;
                              transition: all 0.5s;
                              transition-delay: 0.3s;
                         }
     
                         ~ .c-body {
                              .analytics {
                                   .report-header {
                                        z-index: 4030;
                                   }
                              }
                         }
                    }

                    .get-help {
                         bottom: 24px;
                    }

                    .btn-compare-plans {
                         opacity: 0;
                         visibility: hidden;
                         pointer-events: none;
                    }
               }
     
               & ~ .sidebar-modal {
                    .ll-flexible-modal {
                         box-shadow: none;
     
                         &:not(.full-screen) {
                              margin-top: auto;
                              border-radius: 0;
                              max-height: calc(100% - 50px);
                              max-width: calc(100% - 328px) !important;

                              .lookup-table.lookup-table-sticky {
                                   margin-top: 50px;
                              }
                         }
     
                         &.full-screen {
                              ~ .ll-flexible-modal {
                                   max-height: 100%;
                                   border-top-left-radius: 12px;
                                   border-bottom-left-radius: 12px;
                              }
                         }

                         .library-wrapper {
                              padding-bottom: 60px;
                         }
                    }
               }
     
               & ~ .screen-guide {
                    z-index: 4030;
               }
          }
     
          &:has(.ll-flexible-modal.full-screen) {
               .header.new-header,
               .analytics .report-header {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
               }
          }
     
          &.modal-open {
               .sidebar.new-sidebar {
                    & ~ .unsaved-changes-popup,
                    & ~ .sidebar-modal .ll-flexible-modal:has(.modal.show) {
                         z-index: 4050;
     
                         .modal-backdrop {
                              transition: all 0.3s;
                              transition-delay: 0.05s;
                         }
                    }
               }
          }
     }
}

html:not([dir="rtl"]) .breadcrumb-item+.breadcrumb-item::before {
     padding-right: 0.2rem;
     padding-left: 0.2rem;
}
html:not([dir="rtl"]) .breadcrumb-item + .breadcrumb-item{
     padding-left: 0.1rem;
}