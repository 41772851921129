// Here you can add other styles
body {
     overflow-x: hidden;
}

main.c-main .container-dashboard {
     padding-left: 0px !important;
     padding-right: 0px !important;
}

.c-app {
     color: #000;
     // overflow-x: hidden;
}

.pointer {
     cursor: pointer;
}

.card {
     border: unset !important;
     margin-bottom: 1.5rem;
}

.ll-disable-scroll {
     overflow-y: hidden;
}

.icon {
     vertical-align: middle;
}

.icon-20 {
     height: 20px;
     min-width: 20px;
     position: relative;
     top: 3px;
}

.connect-salesforce {
     .content {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;  
     }

     .logo {
          width: 640px;
     }
}

.admin {
     .table {
          th {
               white-space: nowrap;
               position: relative;
               padding: 0.75rem;
               padding-right: 2rem;

               svg.icon {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
               }
          }

          thead tr:not(:first-child) th {
               padding: 0.5rem;
          }

          &.company-match {
               th {
                    text-align: center;
               }

               td {
                    text-align: center;
               }
          }
     }
}

.fillgrayscale {
     filter: grayscale(100%) !important;
}

.box-seting {
     max-width: 100%;
}

.update-box {
     img {
          width: 700px;
          height: 100%;
          margin: 0 auto;
     }

     .update-box-content {
          height: 100%;
     }

     h1 {
          font-size: 4rem;
          font-weight: 900;
     }
}

p {
     font-size: 1rem;
}

a {
     text-decoration: none;
     &.text-primary {
          text-decoration: underline;

          &:hover {
               text-decoration: none;
          }
     }
}

.headlineLoadding {
     animation-duration: 4s;
     animation-fill-mode: forwards;
     animation-iteration-count: infinite;
     animation-name: placeHolderShimmer;
     animation-timing-function: linear;
     background: #20a8d8;
     background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
     background-size: 800px 104px;
     position: relative;
     width: 250px;

     &.titleLoading {
          height: 40px;
          margin-bottom: 10px;
     }

     &.descriptionLoading {
          height: 20px;
          width: 50%;
          margin-bottom: 20px;
     }
}

.cardLoading {
     border: 1px solid #e4e7ea !important;
     box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13) !important;
     padding: 1.25rem;
     position: relative;
     margin-bottom: 1.5rem;

     &__item {
          background: #fff;
          border-color: #e5e6e9 #dfe0e4 #d0d1d5;
          border-radius: 3px;
          margin: 0 auto;
          max-width: 100%;
          min-height: 80px;
     }

     &__animated {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background: #f6f7f8;
          background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
          background-size: 800px 104px;
          height: 100%;
          position: relative;

          .padding {
               height: 16px;
               background: #fff;
          }

          .name {
               position: relative;
               height: 20px;

               &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    background: #fff;
                    height: 21px;
                    width: 80%;
               }
          }

          .description {
               position: relative;
               height: 30px;

               &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    background: #fff;
                    height: 31px;
                    width: 40%;
               }
          }

          .button_animated {
               position: relative;
               height: 30px;

               &:after {
                    position: absolute;
                    right: 0;
                    content: '';
                    background: #fff;
                    height: 31px;
                    width: 93%;
               }
          }

          .padding_button {
               position: absolute;
               background: #fff;
               bottom: 0px;
               left: 48px;
               height: 31px;
               width: 20px;
          }

          .item {
               position: relative;
               height: 250px;
          }

          .item-padding {
               position: absolute;
               background: #fff;
               bottom: 0px;
               left: 440px;
               height: 100%;
               width: 30px;
          }

          .item-padding2 {
               position: absolute;
               background: #fff;
               bottom: 0px;
               left: 910px;
               height: 100%;
               width: 30px;
          }
     }
}

.tableLoading {
     &__main {
          position: relative;
          display: block;
     }

     &__header {
          background-color: #18222b;
          height: 45px;
     }

     &__item {
          background: #fff;
          border-color: #e5e6e9 #dfe0e4 #d0d1d5;
          border-radius: 3px;
          margin: 0 auto;
          max-width: 100%;
          min-height: 200px;
     }

     &__animated {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background: #f6f7f8;
          background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
          background-size: 800px 104px;
          height: 100%;
          position: relative;
     }

     .row1 {
          height: 50px;
     }

     .row2 {
          height: 50px;
          background-color: #fff;
     }
}

@keyframes placeHolderShimmer {
     0% {
          background-position: -1200px 0;
     }

     100% {
          background-position: 1200px 0;
     }
}

@keyframes placeHolderShimmer2 {
     0% {
          background-position: -468px 0;
     }

     100% {
          background-position: 468px 0;
     }
}

.text-top-right {
     position: absolute;
     top: 0;
     right: 0;
     // color: $gray-600 !important;
     color: #708393;
     text-decoration: underline;
     font-family: Roboto;
     font-size: 14px;
     font-weight: 400;

     &:hover {
          text-decoration: none;
     }
}

.c-app {
     form {
          .form-group,
          .form-control {
               color: #2f353a;
          }
     }
}

.header-nav {
     .switch-account-nav {
          .switch-account-btn {
               border-left: 1px $secondary solid !important;
               border-right: 1px $secondary solid !important;

               .label {
                    font-size: 11px;
                    color: #c8ced3;
               }
          }

          .account-name {
               font-size: 14px;
               font-weight: 400;
               letter-spacing: 0.2px;
               color: #708393;

               &.active {
                    color: #63c2de;
               }
          }

          .account-select {
               padding-top: 0;
          }

          .label-current {
               font-size: 9px;
               letter-spacing: 0.13px;
               color: #63c2de;
               font-weight: 700;
               color: #63c2de;
               font-family: Roboto;
               font-size: 9px;
               font-weight: 700;
               line-height: 11px;
               text-align: center;
          }
     }
}

// .c-dark-theme {
//      .css-yk16xz-control,
//      .css-1pahdxg-control {
//           background-color: inherit;
//      }
//      .header-nav {
//           .switch-account-nav {
//                .switch-account-btn {
//                     border-left: 1px #fff solid;
//                     border-right: 1px #fff solid;
//                     span {
//                          color: #fff;
//                     }
//                }
//           }
//      }
//      .subscriber-dashboard {
//           .upgrade-package {
//                h4 {
//                     color: #e1e1e1;
//                }
//           }
//           .account-item {
//                .item-name {
//                     color: #e1e1e1;
//                }
//           }
//      }
// }

.c-footer {
     border-top: 0;
     flex: 1;
}

.c-body {
     // background-color: #f7f8f9;
     background-color: #fff;
}

.header {
     box-shadow: none !important;

     .c-subheader {
          background-color: #f1f3f5;
     }
}

.breadcrumb-item {
     a {
          color: #73818f;
     }
}

.header-nav {
     a {
          &:not(.btn) {
               color: #708393;
               text-decoration: none !important;
          }
     }
}

.c-footer {
     // background-color: #f7f8f9;
     background-color: #fff;
}

.page-item {
     .page-link {
          &:hover,
          &:focus,
          &:active {
               outline: none !important;
               box-shadow: none !important;
          }
     }
}

.remove-hover {
     &:hover {
          background: inherit !important;
     }
}

.back-to-link {
     color: #212c36 !important;
     font-weight: 500;
     // font-size: rem(12);
     position: relative;
     font-family: Roboto;
     font-size: 14px;
     font-weight: 400;
     line-height: 17px;
     letter-spacing: 0.2px;
     padding-left: 30px;
     cursor: pointer;

     svg {
          position: absolute;
          @include position-left-middle();
          margin-left: 32px;
     }

     span {
          margin-left: 16px;
     }
}

select {
     cursor: pointer;
}

a {
     cursor: pointer;
}

.sun-editor-editable {
     border-radius: 12px;
     overflow: hidden;

     code {
          font-size: 87.5%;
          color: #e83e8c;
          word-wrap: break-word;
     }

     b {
          font-weight: bold;
     }
}

.sun-editor {
     border-radius: 12px;

     .se-menu-list {
          .se-btn {
               font-family: 'roboto';
          }
     }

     .se-dialog {
          ._se_bookmark_button {
               display: none;
          }
     }

     .se-container {
          .se-toolbar {
               border-top-left-radius: 12px;
               border-top-right-radius: 12px;
          }

          .se-resizing-bar {
               border-bottom-left-radius: 12px;
               border-bottom-right-radius: 12px;
          }

          .se-show-block h1 {
               -webkit-text-fill-color: currentColor;
          }
     }
}

.sun-editor,
.sun-editor-editable {
     font-family: 'Helvetica Neue', sans-serif !important;
}

.login-foot {
     display: flex;
     justify-content: space-between;

     .btn-login {
          min-width: 110px;
     }
}

.Toastify__toast-body {
     width: 100%;
     word-break: break-word;
     padding-right: 15px;
}

.add-margin-cheat-sheet {
     margin-bottom: 250px !important;
}

.trigger-rule {
     .add-margin-cheat-sheet {
          margin-bottom: 150px !important;
     }
}

.apexcharts-menu-item.exportCSV {
     display: none;
}

.react-select {
     &-custom {
          &__control {
               border-color: #e2e6e9 !important;
               box-shadow: 0 0 1px #e2e6e9 !important;
               &:hover,
               :focus {
                    border-color: #e2e6e9 !important;
                    box-shadow: unset;
               }
               .react-select-custom__input-container .react-select-custom__input {
                    padding-left: 0 !important;
               }
          }
     }
     &__value-container {
          font-size: 0.9rem !important;
     }
}

.select-react-customize {
     .select-wrapper {
          position: relative;

          > input,
          .stretch-container {
               background: #ffff;
               border: 1px solid #e2e6e9 !important;
               background-image: url(/assets/icons/icon-select.svg);
               background-repeat: no-repeat;
               background-position-x: calc(100% - 12px);
               background-position-y: center;
               background-size: 6px;
               text-align: left;
               cursor: pointer;

               &:disabled {
                    background-color: rgb(242, 242, 242);
                    cursor: default;
               }
          }

          .custom-select__option.custom-select__option--is-selected {
               color: #20a8d8;
               background-color: #f3f5f6;
          }
          .custom-select__option {
               &--is-focused {
                    background-color: unset;
               }
          }

          .custom-select__value-container {
               cursor: text;
          }

          // .css-1hb7zxy-IndicatorsContainer,
          // .css-1wy0on6 {
          //      display: none;
          // }
          .custom-select__control,
          .css-1ycljee-control {
               min-width: auto;
               // padding: 0 5px;
               cursor: text;
               border-color: #e4e7e9 !important;
               box-shadow: none;
               border-radius: 12px;

               .css-g1d714-ValueContainer {
                    cursor: text;
               }
          }

          .custom-select__placeholder {
               width: 98%;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
               text-align: left;
          }

          .form-control[readonly] + div,
          .stretch-container.form-control + div {
               background-color: transparent !important;
          }

          .stretch-container.form-control {
               &.disabled {
                    background-image: url(/assets/icons/lock-dropdown.svg);
                    background-size: 12px 13px;
                    background-color: #e4e7ea;
                    background-repeat: no-repeat;
                    background-position: center right 20px;
               }
          }

          .custom-select__control {
               .custom-select__input {
                    box-sizing: content-box;
               }
          }
     }

     &.not-selected {
          .select-wrapper .form-control {
               color: #7f8c9a;
          }
     }
}

div[class$='container'] {
     div[class$='-menu'] {
          overflow: hidden;

          &:has(.no-options) {
               overflow: unset;
          }
     }

     div[class*='-MenuList'],
     div[class$='-control'] > div[class*='css']:nth-of-type(2) > div[class*='css'],
     div[class$='-menu'] > div[class*='css'],
     .css-11unzgr,
     .css-1qmqbrx,
     .css-cz7poj,
     .css-1g311x {
          &::-webkit-scrollbar-track {
               box-shadow: none;
               border-radius: 12px;
               width: 3px;
               background-color: #e2e6e9;
          }

          &::-webkit-scrollbar {
               width: 3px;
               border-radius: 12px;
          }

          &::-webkit-scrollbar-thumb {
               background: #73818f;
               border-radius: 12px;
               border: none;
               width: 3px;
          }
     }

     div[class$='-option'] {
          font-size: 14px;
          color: #7f8c9a;
          letter-spacing: 0.2px;
          cursor: pointer;
          word-wrap: break-word;

          &.react-select__option,
          &:hover {
               color: #20a8d8;
               background-color: #f3f5f6 !important;
          }

          &.react-select__option {
               &.react-select__option--is-selected {
                    color: #20a8d8 !important;
                    background-color: #f3f5f6 !important;
               }
               color: #7f8c9a !important;
               background-color: #fff !important;

               &:hover {
                    color: #20a8d8 !important;
                    background-color: #f3f5f6 !important;
               }
          }
          &.dashboard {
               &.react-select__option {
                    &.react-select__option--is-selected {
                         color: #424242 !important;
                         background-color: #fff !important;
                    }

                    &:hover {
                         color: #0098d3 !important;
                         background-color: #f4f4f5 !important;
                    }
               }
          }
     }
}

.select-option.dashboard {
     div[class$='container'] {
          div[class$='-option'] {
               &.react-select__option {
                    &.react-select__option--is-selected {
                         color: #424242 !important;
                         background-color: #fff !important;
                         &:hover {
                              color: #0098d3 !important;
                              background-color: #f4f4f5 !important;
                         }
                    }
               }
          }
     }
}

// .placeholder {
//      > div:last-of-type {
//           position: relative;
//           &:after {
//                content: "Type here to filter the list…";
//                margin-top: 2px;
//                position: absolute;
//                left: 0;
//           }
//      }
//      input[type="text"] {
//           max-width: 170px !important;
//           width: 170px !important;
//      }
// }

.loading-center {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     z-index: 10;
     background-color: rgba(0, 0, 0, 0.02);
}

.certificate-pdf-viewer,
.invoice-pdf-viewer {
     padding: 0;
     margin: 0;
     background-color: #f0f0f2;
     display: block;
     min-height: 100vh;
     position: relative;

     .loading {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
     }

     iframe {
          width: 100%;
          height: 100vh;
          display: block !important;
     }

     .box-not-complete {
          width: 600px;
          padding: 2em;
          background-color: #fdfdff;
          border-radius: 0.5em;
          box-shadow: 2px 3px 7px 2px rgba(126, 126, 126, 0.2);
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
     }
}

.publish-downgrade {
     .modal {
          z-index: 1056;
     }

     .modal-backdrop {
          z-index: 1055;
     }
}

.no-border {
     border: none !important;
}

.input-message {
     font-size: 13px;
     margin-top: 5px;
     margin-bottom: 0;

     a {
          text-decoration: underline;
     }
}

.tooltip-wrapper,
.no-options-message > span,
.extra-note,
.icon-tooltip-block {
     > .icon,
     > .icon:not(.icon-c-s):not(.icon-custom-size) {
          width: 12px !important;
          height: 12px !important;
     }
}

@media screen and (max-width: 991px) {
     .c-wrapper {
          margin-left: 0 !important;
     }
}

.sidebar-backdrop {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 1030;
     width: 100vw;
     height: 100vh;
     background-color: #000;
     transition: 0.3s;
     opacity: 0.5;
}

.container-fluid {
     padding-right: 30px !important;
     padding-left: 24px !important;
}

.c-main > .container-fluid:has(.analytics.not-container) {
     padding: 0px !important;
}

.show-nav-child {
     .c-main > .container-fluid:has(.analytics.not-container) {
          padding: 0px !important;
          padding-left: 5px !important;
     }
}

.customDisabled {
     opacity: 0.5;
     pointer-events: none;
}

.containerCustomDropDown {
     position: relative;
     cursor: pointer;

     .dropdownMenuItem {
          width: max-content;
          border: 1px solid #ebebeb;
          border-radius: 10px;
          z-index: 101;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          background: white;
          position: absolute;
          max-height: 400px;
          overflow: auto;

          input {
               width: 100%;
               height: 100%;
               padding: 10px 15px;
               border: none;
               outline: none;
               border-radius: 10px;
               position: sticky;
               top: 0;
               font-size: 13px;

               ~ ul {
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 0;

                    li {
                         padding: 4px 15px;
                         font-size: 13px;

                         &:hover {
                              background: #b4b4b4;
                         }
                    }

                    li:first-child {
                         padding: 8px 15px 4px 15px;
                         border-top: 1px solid #ebebeb;
                    }

                    li:last-child {
                         border-bottom-left-radius: 10px;
                         border-bottom-right-radius: 10px;
                         padding: 4px 15px 8px 15px;
                    }
               }
          }

          ul {
               list-style-type: none;
               padding: 0;
               margin-bottom: 0;

               li {
                    padding: 4px 15px;

                    &:hover {
                         background: #b4b4b4;
                    }
               }

               li:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 8px 15px 4px 15px;
                    border-top: 1px solid #ebebeb;
               }

               li:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 4px 15px 8px 15px;
               }
          }
     }
}

.k-chart-shared-tooltip {
     background-color: white !important;
     padding: 0 !important;
}

.tooltip-content {
     white-space: pre-wrap;
     font-size: 14px !important;
     text-align: start;
     padding: 10px;
}

.loadingTableTooltip {
     &.plan-features {
          background-color: #fff;
          color: #fff;
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);

          .tippy-arrow {
               background-color: white;
               border-right-color: white;
               border-left-color: white;

               &::before {
                    border-right-color: white;
                    border-left-color: white;
               }
          }

          .tooltip-content {
               white-space: unset;
               padding: 10px 9px;
          }

          & > .tippy-content {
               background: #fff;

               span {
                    color: #20a8d8;
               }
          }
     }

     &.tippy-box {
          border-radius: 15px;
          & > .tippy-svg-arrow {
               bottom: 0px;
               right: -5px;
               z-index: unset;
               rotate: 180deg;
               transform: translate(-9px, 15px) !important;
     
               svg {
                    width: 16px;
                    z-index: 1;
               }
          }
     }

     & > .tippy-content {
          max-width: 640px;
          border-radius: 15px;
     }
}

.custom-overlay {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(to top, rgba(255, 255, 255, 0.8), transparent);
     backdrop-filter: blur(2px);
     z-index: 2;
}

.custom-popup {
     position: absolute;
     top: calc(100vh / 3);
     width: max-content;
     padding: 10px 20px;
     background: rgb(240, 243, 245);
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     border-radius: 10px;
     color: #424242;
     z-index: 3;
}

.bg {
     &-warning {
          color: #23282c;
     }
}

.bg-pricing {
     background: url(/assets/images/background-pricing.png) no-repeat;
     background-size: 100%;
     background-color: #f7f9fb;
}

.text-link {
     margin-left: 4px;
     cursor: pointer;
     text-decoration: underline;
}

.tippy-box[data-theme~='user-source'] {
     .tippy-content {
          max-width: max-content !important;
          background: rgb(5, 5, 5);
          .tooltip-content {
               .title {
                    max-width: max-content;
               }
               .content {
                    word-break: break-all;
                    max-width: 1000px;
               }
          }
     }
}

.tippy-box[data-theme~='segment'] {
     .tippy-content {
          max-width: max-content !important;
          background: rgb(5, 5, 5);
          padding: 0;
          .tooltip-content {
               padding: 5px;
               .title {
                    font-size: 12px;
                    max-width: max-content;
               }
               .content {
                    word-break: break-all;
                    max-width: 1000px;
               }
          }
     }
}

.tippy-box[data-theme~='white'] {
     border-radius: 12px;

     .tippy-content {
          max-width: 302px !important;
          padding: 10px 16px;
          border: solid 0.5px #c8ced3;
          background: #fff;
          color: #000;
          font-size: 12px;
          letter-spacing: 0.08px;
          font-weight: 400;
          line-height: 17px;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 10px !important;
          border-radius: 12px;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
          text-align: unset;

          p {
               font-family: 'Roboto';
               margin: 0;
               color: #000;
               line-height: 21px;
               font-size: 14px;
               font-weight: 400;
               letter-spacing: 0;
          }
     }

     .tippy-svg-arrow {
          top: -3px !important;
          left: -8px;
          z-index: 1;

          svg {
               width: 9px;

               path {
                    fill: #ffff !important;
               }
          }
     }
}

.tippy-box[data-theme~='info'] {
     border-radius: 12px;

     .tippy-content {
          max-width: 290px !important;
          padding: 2px 12px;
          border: solid 0.5px #c8ced3;
          font-size: 12px;
          letter-spacing: 0.08px;
          font-weight: 400;
          line-height: 17px;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 10px !important;
          border-radius: 12px;
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.13);
          text-align: unset;

          p {
               font-family: 'Roboto';
               margin: 0;
               line-height: 21px;
               font-size: 14px;
               font-weight: 400;
               letter-spacing: 0;
          }
     }

     .tippy-svg-arrow {
          top: -2px !important;
          left: -8px;
          z-index: 1;

          svg {
               width: 9px;
          }
     }
}

.tippy-box[data-theme~='left-back'] {
     .tippy-svg-arrow {
          top: -3px !important;
          left: -7px;
          z-index: 1;

          svg {
               width: 9px;
          }
     }
}

.tippy-box[data-theme~='hidden'] {
     display: none;
}

.tippy-box[data-theme~='left-top-back'] {
     border-radius: 8px;
     .tippy-content {
          max-width: 370px !important;
          border-radius: 8px !important;
     }
     .tippy-svg-arrow {
          top: -12px !important;
          // left: -3px !important;
          left: 0 !important;
          z-index: 1;
     }
     &.date-range {
          .tippy-content {
               max-width: 250px !important;
          }
     }
}

.k-animation-container {
     .k-tooltip {
          .k-callout {
               color: #050505;
          }
     }
}

.k-chart-tooltip-wrapper {
     z-index: 12000 !important;
     transition: initial !important;
}

.historical-popup {
     .custom-date-picker {
          width: 300px;
          padding: 10px 15px;
          font-weight: 500;
          background-clip: padding-box;
          border: 1px solid;
          background-color: #fff;
          border-color: #e4e7ea;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          border-radius: 20px !important;

          .rdrMonthAndYearWrapper {
               margin-top: 0px;
          }

          .rdrDateDisplayWrapper .rdrDateDisplay:first-child {
               margin-bottom: 17px;
          }
     }
}

.c-switch-sm .c-switch-slider::before {
     width: 18px;
     height: 18px;
     top: 1px;
     left: 1px;
}
.k-popup:has(.large-kpi-tooltip) {
     box-shadow: none !important;
}

.table {
     &.table-dark {
          & > :not(caption) > * > * {
               color: $white;
          }
     }
     & > :not(caption) > * > * {
          border-bottom-width: 0;
          color: #2f353a;
     }
}

.badge-secondary {
     color: #23282c;
     background-color: #ced2d8;
}

@keyframes l1 {
     100% {
          box-shadow: 0 0 0 30px #0000;
     }
}

.footer {
     display: block;
}

.custom-file-label {
     border-color: #e4e7ea !important;
}
